<template>
    <div>
        <surround-loader :is-loading="isLoading">
            <div class="p-4">
                <h2 class="mb-4">Launch</h2>
                <p>Thank you for completing your setup.</p>
                <p>Access Right to Work is now ready to launch. Please click the Launch Access Right to Work button at
                    the
                    bottom of the screen. You will be able to access the Right to Work system to start conducting Right to
                    Work
                    checks with your applicants.</p>
                <p>We wish you all the success in using the system</p>
                <p>Please refer to the support tool within the system for any product related queries</p>
            </div>
            <div class="p-4  d-flex justify-content-between">

                <b-button :to="{name:'setup_payment'}" variant="outline-secondary">
                    Back
                </b-button>

                <b-button @click="saveForm" variant="success">
                    Launch Access Right to Work
                </b-button>
            </div>
        </surround-loader>
    </div>
</template>

<script>
    import {getResource} from "../../modules/api/methods";
    import {organisationSetupLaunch} from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";
    import SurroundLoader from "../SurroundLoader";

    export default {
        name: 'setup_launch',
        data: function () {
            return {
                errors: {},
                isLoading: false,
            }
        },
        methods: {
            saveForm() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                getResource(organisationSetupLaunch).then(function (resp) {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        console.log("JUST UPDATED ORG WITH ",resp.data.success.data);
                        vm.$router.push({name: 'dashboard'}).catch(error => {});
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
        },
        components: {SurroundLoader},
    }
</script>

